import React from 'react'
import { Link } from 'gatsby'

const Pagination = ({ pageContext }) => {
  const {
    previousPagePath,
    nextPagePath,
    humanPageNumber,
    numberOfPages,
  } = pageContext

  return (
    <ul
      className="pagination pagination-inside-transparent pagination-lg"
      role="navigation"
    >
      {previousPagePath ? (
        <li className="page-item">
          <Link className="page-link" to={previousPagePath} rel="prev">
            上一页
          </Link>
        </li>
      ) : (
        <li className="page-item disabled">
          <a
            className="page-link text-muted"
            href="#"
            tabIndex={-1}
            aria-disabled="true"
          >
            上一页
          </a>
        </li>
      )}
      <li className="page-item disabled">
        <a className="page-link" href="#">
          {humanPageNumber}
          <span className="px-1"> / </span>
          {numberOfPages}
        </a>
      </li>
      {nextPagePath ? (
        <li className="page-item">
          <Link className="page-link" to={nextPagePath} rel="next">
            下一页
          </Link>
        </li>
      ) : (
        <li className="page-item disabled">
          <a
            className="page-link text-muted"
            href="#"
            tabIndex={-1}
            aria-disabled="true"
          >
            下一页
          </a>
        </li>
      )}
    </ul>
  )
}

export default Pagination
