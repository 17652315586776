import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { MdxArticleFields } from '../models/mdx'
import EntryImage from './entryImage'

type DataProps = {
  article: MdxArticleFields
}

const FlatArticle: React.FC<DataProps> = ({ article }) => {
  const url = `/${article.fields.slug}/`
  const { title, description } = article.frontmatter

  return (
    <div className="entry col-12">
      <div className="grid-inner row g-0">
        <div className="col-md-5 overflow-hidden">
          <EntryImage article={article} />
        </div>
        <div className="col-md-7 ps-md-5">
          <div className="entry-title title-sm">
            <h2>
              <Link to={url}>{title}</Link>
            </h2>
          </div>
          <div className="entry-meta">
            <ul>
              <li>
                <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                {article.frontmatter.published_at}
              </li>
            </ul>
          </div>
          <div className="entry-content">
            <p>{description || article.excerpt}</p>
            <Link to={url} className={'more-link'}>
              继续阅读
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FlatArticle
