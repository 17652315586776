import React from 'react'
import Pagination from './pagination'
import FlatArticle from './flatArticle'
import Section from './section'
import GoogleAd from './googleAd'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'

type DataProps = {
  articles: any[]
  title?: string
  subTitle?: string
  pageContext: any
  disablePagination?: boolean
  showLinkToAll?: boolean
}

const ArticlesFlatList: React.FC<DataProps> = ({
  articles,
  title,
  subTitle,
  pageContext,
  disablePagination,
  showLinkToAll,
}) => (
  <Section transparent={true}>
    {title && (
      <div className="heading-block center">
        <h3>{title}</h3>
        {subTitle && <span>{subTitle}</span>}
      </div>
    )}
    <div className="row gutter-40 col-mb-80">
      <div className="postcontent col-lg-9">
        <div id="posts" className="row grid-container gutter-40">
          {articles.map(({ node }) => (
            <FlatArticle key={node.id} article={node} />
          ))}
        </div>
      </div>
      <div className="sidebar col-lg-3">
        <div className="widget clearfix mt-3 text-center">
          <h4>广告</h4>
          <GoogleAd
            client="ca-pub-5852717197941523"
            slot="2903832143"
            style={{ display: 'block' }}
            format="auto"
            responsive="true"
          />
        </div>
      </div>
    </div>
    {!disablePagination && (
      <div className="row justify-content-center">
        <Pagination pageContext={pageContext} />
      </div>
    )}
    {showLinkToAll && (
      <div className="row justify-content-center">
        <Link to="/articles/">
          <button className="button button-xlarge button-amber button-rounded text-right">
            查看全部博客文章
            <FontAwesomeIcon className="ml-1" icon={faArrowCircleRight} />
          </button>
        </Link>
      </div>
    )}
  </Section>
)

export default ArticlesFlatList
