import React from 'react'

type DataProps = {
  className?: string
  style: any
  client: string
  slot: string
  layout?: string
  format: string
  responsive: string
}

class GoogleAd extends React.Component<DataProps> {
  componentDidMount() {
    try {
      if (window) {
        // @ts-ignore
        ;(window.adsbygoogle = window.adsbygoogle || []).push({})
      }
    } catch (e) {}
  }

  render() {
    return (
      <ins
        className={`${this.props.className || ''} adsbygoogle`}
        style={this.props.style}
        data-ad-client={this.props.client}
        data-ad-slot={this.props.slot}
        data-ad-layout={this.props.layout || ''}
        data-ad-format={this.props.format}
        data-full-width-responsive={this.props.responsive}
      />
    )
  }
}

export default GoogleAd
