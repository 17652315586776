import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from './layout'
import ArticlesFlatList from '../components/articlesFlatList'
import { MdxArticleFields } from '../models/mdx'
import Section from '../components/section'

type DataProps = {
  allMdx: {
    edges: MdxArticleFields[]
  }
}

const ArticlesLayout: React.FC<PageProps<DataProps>> = ({
  data,
  pageContext,
}) => {
  const articles = data.allMdx.edges

  return (
    <>
      <Layout pageTitle="全部博客文章" pageSubTitle="不同主题的博客文章">
        <Section transparent={true}>
          <div className="container clearfix">
            <ArticlesFlatList articles={articles} pageContext={pageContext} />
          </div>
        </Section>
      </Layout>
    </>
  )
}

export default ArticlesLayout

export const pageQuery = graphql`
  query MdxArticleQuery($limit: Int!, $skip: Int!) {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/articles/" } }
      sort: { order: DESC, fields: [frontmatter___published_at] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...MdxArticleFields
        }
      }
    }
  }
`
